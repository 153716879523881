import React, { useEffect } from 'react';
import EMEABreadcrumb from 'components/EMEA/EMEA_Common/EMEABreadcrumb';
import EMEAInvoiceStatus from 'components/EMEA/EMEAInvoiceStatus';
import useSetQuery, { useMultiSetQuery } from "customHooks/useQuery";
import { defaultDocumentDate } from "configs/appConstants";
import EMEAInvoiceKPIs from 'components/EMEA/EMEAInvoiceKPIs';

const EMEAInvoices = () => {
  const setMultiQuery = useMultiSetQuery();
  const [params] = useSetQuery();
  
  useEffect(() => {
    if (params.loadView) {
      setMultiQuery({
        documentDate: JSON.stringify(
          params.loadView ? defaultDocumentDate : params.documentDate
        ),
        loadView: undefined
      });
    }
  }, [params.loadView, params.documentDate]);

  return (
    <>
      <EMEABreadcrumb pageName={'invoices'} />
      <EMEAInvoiceStatus />
      <EMEAInvoiceKPIs />
    </>
  )
}

export default EMEAInvoices;