import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  box: {
    backgroundColor: theme.palette?.card?.default,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(3)
  },
  buttonLink: {
    color: theme.palette?.link?.primary,
    textTransform: 'capitalize',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 12
    }
  },
  paper: {
    width: '30%',
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: theme.palette?.card?.miniCard,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 85
  }
}));

const EMEAInvoiceStatus = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleLinkClick = () => {};

  return (
    <Box className={classes.box} data-testid="invoice-status-kpi">
      <Grid container justifyContent="space-between" >
        <Typography variant="h6"> {t('invoices')} </Typography>
        <Button className={classes.buttonLink} disableRipple data-testid="page-link-btn" size='small' onClick={handleLinkClick} >
          {t("go_to_invoice_details")}
        </Button>
      </Grid>
      <Grid container justifyContent="space-around" >
        <Paper className={classes.paper}>
          <Typography variant="h3"> <strong> 1.29M (dummy) </strong> </Typography>
          <Typography variant="body1"> {t('invoiced_mtd')} - August 2024 </Typography>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h3"> <strong> 70.46K (dummy) </strong> </Typography>
          <Typography variant="body1"> {t('invoiced_today')} </Typography>
        </Paper>
      </Grid>
    </Box>
  )
}

export default EMEAInvoiceStatus;