import ordersfilters from "./ordersFilters";
import transportfilters from "./transportFilters";
import distoutboundfilters from "./distoutboundFilters";
import distinboundfilters from "./distinboundFilters";
import networkfilters from "./networkFilters";
import stockConstraintReportFilter from "./stockConstraintReportFilters";
import osmFilters from "./osmFilters";

//EMEA
import emeaCustReturnsFilters from "./EMEA/emeaCustReturnsFilters";
import emeaOpenOrdersFilters from "./EMEA/emeaOpenOrdersFilters";
import emeaOrderCutsAndRejectionsFilters from "./EMEA/emeaOrderCutsAndRejectionsFilters";

//LAO
import laoOrderDetailsFilters from "./LAO/laoOrderDetailsFilters";
import laoImportOrderDetailsFilters from "./LAO/laoImportOrderDetailsFilters";

export const tooltipLinkArgumentsMap = {
    //Order and Network
    'SO_BLOCKED': { orderStatusBucket: ["SO_BLOCKED_NON_TMS_PLANNED_IMMEDIATE_ACTION", "SO_BLOCKED_TMS_PLANNED_VISIBLE",'SO_BLOCKED_TMS_PLANNED_NOT_VISIBLE','SO_BLOCKED_NON_TMS_PLANNED_PICKUP_NOT_SCHEDULED','SO_BLOCKED_NON_TMS_PLANNED_PICKUP_MULTI_BLOCK']},
    'SO_BLOCK_FREE' : {orderStatusBucket: ["SO_COMPLETELY_CONFIRMED_CUBE", "SO_NOT_COMPLETELY_CONFIRMED_CUBE"]},
    'SO_BLOCKED_TMS_PLANNED_VISIBLE':{orderStatusBucket: ["SO_BLOCKED_TMS_PLANNED_VISIBLE"]},
    'SO_BLOCKED_TMS_PLANNED_NOT_VISIBLE':{orderStatusBucket: ["SO_BLOCKED_TMS_PLANNED_NOT_VISIBLE"]},
    'SO_BLOCKED_NON_TMS_PLANNED_PICKUP_NOT_SCHEDULED':{orderStatusBucket: ["SO_BLOCKED_NON_TMS_PLANNED_PICKUP_NOT_SCHEDULED"]},
    'SO_BLOCKED_NON_TMS_PLANNED_IMMEDIATE_ACTION':{orderStatusBucket: ["SO_BLOCKED_NON_TMS_PLANNED_IMMEDIATE_ACTION"]},
    'SO_BLOCKED_NON_TMS_PLANNED_PICKUP_MULTI_BLOCK':{orderStatusBucket: ["SO_BLOCKED_NON_TMS_PLANNED_PICKUP_MULTI_BLOCK"]},
    "SO_BACK_ORDER_BLOCKED": {orderStatusBucket: ["SO_BACK_ORDER_BLOCKED"]},
    "SO_BACK_ORDER_BLOCK_FREE": {orderStatusBucket: ["SO_BACK_ORDER_BLOCK_FREE"]},
    'SO_NOT_COMPLETELY_CONFIRMED_CUBE':{orderStatusBucket: ["SO_NOT_COMPLETELY_CONFIRMED_CUBE"]},
    'STO' : {orderTypes: ["STO"]},

    //Transportation Planning & Transporation Execution 
    'TRANS_PLAN_TO_BE_PLANNED' : {orderExecutionBucket: ["TRANS_PLAN_UNASSIGNED"]},
    'TRANS_PLAN_PLANNING' : {orderExecutionBucket: ["TRANS_PLAN_OPEN"]},
    'TRANS_PLAN_PROCESSING': {orderExecutionBucket: ["TRANS_PLAN_PROCESSING"]},
    'TRANS_PLAN_CONFIRMED' : {orderExecutionBucket: ["TRANS_PLAN_TENDER_ACCEPTED"]},

    'TRANS_EXEC_READY_PICK_UP': {orderExecutionBucket: ["TRANS_EXEC_READY_PICK_UP"]},
    'TRANS_EXEC_SHIPPED': {orderExecutionBucket: ["TRANS_EXEC_IN_TRANSIT"]},
    'TRANS_EXEC_DELIVERY_CONFIRMED':{orderExecutionBucket: ["TRANS_EXEC_DELIVERY_CONFIRMED"]},
    'TRANS_EXEC_IN_TRANSIT': {orderExecutionBucket: ["TRANS_EXEC_IN_TRANSIT"]},
    'TRANS_EXEC_DELIVERED':{orderExecutionBucket: ["TRANS_EXEC_DELIVERY_CONFIRMED"]},

    'TRANS_PLAN_UNASSIGNED' : {orderExecutionBucket: ["TRANS_PLAN_UNASSIGNED"], tmsShipmentStatus:["Unassigned"]},
    'TRANS_PLAN_OPEN' : {orderExecutionBucket: ["TRANS_PLAN_OPEN"], tmsShipmentStatus:["Open"]},

    'TRANS_PLAN_PLANNED': {orderExecutionBucket: ["TRANS_PLAN_PROCESSING"], tmsShipmentStatus:["Planned"]},
    'TRANS_PLAN_TENDERED': {orderExecutionBucket: ["TRANS_PLAN_PROCESSING"], tmsShipmentStatus:["Tendered"]},
    'TRANS_PLAN_TENDER_REJECTED': {orderExecutionBucket: ["TRANS_PLAN_PROCESSING"], tmsShipmentStatus:["Tender Rejected"]},
    'TRANS_PLAN_TENDER_ACCEPTED' : {orderExecutionBucket: ["TRANS_PLAN_TENDER_ACCEPTED"], tmsShipmentStatus:["Tender Accepted"]},


    'TRANS_DETAILS_APPT_STATUS_NOTIFIED': {
        orderExecutionBucket:["TRANS_PLAN_UNASSIGNED","TRANS_PLAN_OPEN","TRANS_PLAN_PROCESSING","TRANS_PLAN_TENDER_ACCEPTED","TRANS_EXEC_READY_PICK_UP","TRANS_EXEC_IN_TRANSIT","TRANS_EXEC_DELIVERY_CONFIRMED"],
        appointmentStatus:["NOTIFIED"],
    },
    'TRANS_DETAILS_APPT_STATUS_PLANNED': {
        orderExecutionBucket:["TRANS_PLAN_UNASSIGNED","TRANS_PLAN_OPEN","TRANS_PLAN_PROCESSING","TRANS_PLAN_TENDER_ACCEPTED","TRANS_EXEC_READY_PICK_UP","TRANS_EXEC_IN_TRANSIT","TRANS_EXEC_DELIVERY_CONFIRMED"],
        appointmentStatus:["PLANNED"],
    },
    'TRANS_DETAILS_APPT_STATUS_SUGGESTED': {
        orderExecutionBucket:["TRANS_PLAN_UNASSIGNED","TRANS_PLAN_OPEN","TRANS_PLAN_PROCESSING","TRANS_PLAN_TENDER_ACCEPTED","TRANS_EXEC_READY_PICK_UP","TRANS_EXEC_IN_TRANSIT","TRANS_EXEC_DELIVERY_CONFIRMED"],
        appointmentStatus:["SUGGESTED"],
    },
    'TRANS_DETAILS_APPT_STATUS_CONFIRMED': {
        orderExecutionBucket:["TRANS_PLAN_UNASSIGNED","TRANS_PLAN_OPEN","TRANS_PLAN_PROCESSING","TRANS_PLAN_TENDER_ACCEPTED","TRANS_EXEC_READY_PICK_UP","TRANS_EXEC_IN_TRANSIT","TRANS_EXEC_DELIVERY_CONFIRMED"],
        appointmentStatus:["CONFIRMED"],
    },
    'TRANS_DETAILS_APPT_STATUS_NOT_YET_CONTACTED': {
        orderExecutionBucket:["TRANS_PLAN_UNASSIGNED","TRANS_PLAN_OPEN","TRANS_PLAN_PROCESSING","TRANS_PLAN_TENDER_ACCEPTED","TRANS_EXEC_READY_PICK_UP","TRANS_EXEC_IN_TRANSIT","TRANS_EXEC_DELIVERY_CONFIRMED"],
        appointmentStatus:["NOT_YET_CONTACTED"],
    },
    'TRANS_DETAILS_APPT_STATUS_NOT_MADE_BY_TRANSPORT': {
        orderExecutionBucket:["TRANS_PLAN_UNASSIGNED","TRANS_PLAN_OPEN","TRANS_PLAN_PROCESSING","TRANS_PLAN_TENDER_ACCEPTED","TRANS_EXEC_READY_PICK_UP","TRANS_EXEC_IN_TRANSIT","TRANS_EXEC_DELIVERY_CONFIRMED"],
        appointmentStatus:["NOT_MADE_BY_TRANSPORT"],
    },


    //Distribution
    'SHIPMENT_CREATED': {orderExecutionBucket: ["SHIPMENT_CREATED"]},
    'CHECK_IN':{orderExecutionBucket: ["CHECK_IN"]},
    'LOADING_STARTED': {orderExecutionBucket: ["LOADING_STARTED"]},

    'SHIPMENT_CREATED_CUST':{orderExecutionBucket: ["SHIPMENT_CREATED"], orderTypes: ['CUST']},
    'SHIPMENT_CREATED_STO':{orderExecutionBucket: ["SHIPMENT_CREATED"], orderTypes: ['STO']},
    'CHECK_IN_CUST':{orderExecutionBucket: ["CHECK_IN"], orderTypes: ['CUST']},
    'CHECK_IN_STO':{orderExecutionBucket: ["CHECK_IN"], orderTypes: ['STO']},
    'LOADING_STARTED_CUST':{orderExecutionBucket: ["LOADING_STARTED"], orderTypes: ['CUST']},
    'LOADING_STARTED_STO':{orderExecutionBucket: ["LOADING_STARTED"], orderTypes: ['STO']},

    'TRANS_EXEC_READY_PICK_UP_CUST':{orderExecutionBucket: ["TRANS_EXEC_READY_PICK_UP"], orderTypes: ['CUST']},
    'TRANS_EXEC_READY_PICK_UP_STO':{orderExecutionBucket: ["TRANS_EXEC_READY_PICK_UP"], orderTypes: ['STO']},
    
    'TRANS_EXEC_READY_PICK_UP_Cust Pickup':{orderExecutionBucket: ["TRANS_EXEC_READY_PICK_UP"], orderTypes: ['CUST'], tariffServiceCodeList :['PKUP']},

    'SHIPMENT_CREATED_Cust Pickup':{orderExecutionBucket: ["SHIPMENT_CREATED"], orderTypes: ['CUST'], tariffServiceCodeList :['PKUP']},
    'CHECK_IN_Cust Pickup':{orderExecutionBucket: ["CHECK_IN"], orderTypes: ['CUST'], tariffServiceCodeList :['PKUP']},
    'LOADING_STARTED_Cust Pickup':{orderExecutionBucket: ["LOADING_STARTED"], orderTypes: ['CUST'], tariffServiceCodeList :['PKUP']},

    'INBOUND_IN_TRANSIT': {inboundOrderExecutionBucket: ["INBOUND_IN_TRANSIT"]},
    'INBOUND_IN_YARD': {inboundOrderExecutionBucket: ["INBOUND_IN_YARD"]},
    'INBOUND_UNLOADING': {inboundOrderExecutionBucket: ["INBOUND_UNLOADING"]},
    'INBOUND_UNLOADING_COMPLETED': {inboundOrderExecutionBucket: ["INBOUND_UNLOADING_COMPLETED"]},

}

export const defaultNetworkFilterArgs = {
    "region": "NA",
    "sites": null,
    "searchType": "HOME_PAGE",
    "orderBy": [
        {
            "key": "orderExecutionHealth",
            "ordering": "desc"
        },
        {
            "key": "orderHealth",
            "ordering": "desc"
        },
        {
            "key": "orderNum",
            "ordering": "asc"
        }
    ],
    "orderExecutionBucket": [],
    "inboundOrderExecutionBucket": [],
    "orderTypes": ["CUST"]
}

export const defaultOrderFilterArgs = {
    "region": "NA",
    "sites": null,
    "searchType": "OM_DETAILS",
    "orderBy": [
      {
        "key": "orderHealth",
        "ordering": "desc"
      },
      {
        "key": "matAvailDate",
        "ordering": "asc"
      },
      {
        "key": "loadReadyDateTime",
        "ordering": "asc"
      },
      {
        "key": "orderNum",
        "ordering": "asc"
      }
    ],
    "orderExecutionBucket": [
      "TRANS_PLAN_UNASSIGNED",
      "TRANS_PLAN_OPEN",
      "TRANS_PLAN_PLANNED",
      "TRANS_PLAN_TENDERED",
      "TRANS_PLAN_TENDER_REJECTED",
      "TRANS_PLAN_TENDER_ACCEPTED",
      "TRANS_EXEC_READY_PICK_UP",
      "TRANS_EXEC_IN_TRANSIT",
      "TRANS_EXEC_DELIVERY_CONFIRMED",
      "SHIPMENT_CREATED",
      "CHECK_IN",
      "LOADING_STARTED",
    ],
    "inboundOrderExecutionBucket": []
}

export const defaultTransportFilterArgs = {
    "region": "NA",
    "sites": null,
    "searchType": "TRANSPORTATION_DETAILS",
    "orderExecutionBucket": [
        "TRANS_PLAN_UNASSIGNED",
        "TRANS_PLAN_OPEN",
        "TRANS_PLAN_PLANNED",
        "TRANS_PLAN_TENDERED",
        "TRANS_PLAN_TENDER_REJECTED",
        "TRANS_PLAN_TENDER_ACCEPTED",
        "TRANS_EXEC_READY_PICK_UP",
        "TRANS_EXEC_IN_TRANSIT",
        "TRANS_EXEC_DELIVERY_CONFIRMED"
    ],
    "orderBy": [
        {
        "key": "orderExecutionHealth",
        "ordering": "DESC"
        },
        {
        "key": "loadReadyDateTime",
        "ordering": "ASC"
        }
    ]
}

export const defaultInboundFilterArgs = {
    "region": "NA",
    "searchType": "DIST_INBOUND_DETAILS",
    "inboundOrderExecutionBucket": ["INBOUND_IN_TRANSIT", "INBOUND_IN_YARD", "INBOUND_UNLOADING", "INBOUND_UNLOADING_COMPLETED"],
    "orderBy": [
        {
            "key": "inboundOrderExecutionHealth",
            "ordering": "DESC"
        }
    ]
}

export const defaultOutboundFilterArgs = {
    "region": "NA",
    "searchType": "DIST_OUTBOUND_DETAILS",
    "orderExecutionBucket": ["SHIPMENT_CREATED", "CHECK_IN", "LOADING_STARTED", "TRANS_EXEC_READY_PICK_UP"],
    "orderBy": [
      {
        "key": "orderExecutionHealth",
        "ordering": "DESC"
      },
      {
        "key": "loadReadyDateTime",
        "ordering": "ASC"
      }
    ]
}

export const tableHealthCodeObject = {
    network: {
        healthKey: "orderHealthAndExecutionHealth",
        healthCodeArrayKey: "orderStatusAndExecutionStatusReasonCodes"
    },
    orders: {
        healthKey: "orderHealth",
        healthCodeArrayKey: "orderStatusReasonCodes"
    },
    transport: {
        healthKey: "orderExecutionHealth",
        healthCodeArrayKey: "orderExecutionReasonCodes"
    },
    distoutbound: {
        healthKey: "orderExecutionHealth",
        healthCodeArrayKey: "orderExecutionReasonCodes"
    },
    distinbound: {
        healthKey: "inboundOrderExecutionHealth",
        healthCodeArrayKey: "inboundOrderExecutionReasonCodes"
    }
}

export {
    transportfilters,
    distoutboundfilters,
    distinboundfilters,
    ordersfilters,
    networkfilters,
    stockConstraintReportFilter,
    osmFilters,
    emeaCustReturnsFilters,
    emeaOpenOrdersFilters,
    emeaOrderCutsAndRejectionsFilters,
    laoOrderDetailsFilters,
    laoImportOrderDetailsFilters
}