import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, IconButton, Typography } from '@material-ui/core';
import { RefreshOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { emeaSummaryService } from 'reduxLib/services/EmeaServices/emeaSummaryService';
import { boxShadow as defaultBoxShadow, chartsHeight } from 'helpers/styleHelpers';
import { useDeepCompareEffect } from 'react-use';
import { useTranslation } from 'react-i18next';
import { formatLocaleNumber, getInitialDateobj } from 'helpers/emeaHelpers';
import { getDocumentDate } from "configs/appConstants";
import TrendLineChart from '../EMEA_Charts/TrendLineChart';
import DonutChart from '../EMEA_Charts/DonutChart';
import TreeMap from '../EMEA_Charts/TreeMap';
import EMEASelectMonthFilter from './EMEASelectMonthFilter';
import { useEMEAGlobalContext } from 'context/EMEAGlobalContext';
import useSetQuery from 'customHooks/useQuery';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        height: "auto",
        minHeight: theme.spacing(25),
        borderRadius: theme.spacing(1),
        display: "flex",
        background: theme.palette.card.default,
        padding: theme.spacing(3),
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    title: {
        marginRight: "auto",
        paddingBottom: theme.spacing(2),
        fontSize: 18,
        textTransform: "none",
    },
    chartCard: {
        height: ({ height, legendGroup }) => legendGroup ? height + 35 : height,
        borderRadius: theme.spacing(3),
        boxShadow: ({ boxShadow }) => boxShadow,
        maxWidth: ({ maxWidth }) => maxWidth,
        minWidth: ({ minWidth }) => minWidth,
    }
}));

const EMEAChartCard = ({
    subtype,
    title,
    chart,
    trendline,
    showTotal,
    unitOfTotal,
    yLabel,
    legend,
    excludedFilters = {},
    monthFilter,
    showMonthFilter=false,
    customDocDate = null,
    refresh=false,
    setRefresh,
    legendGroup,
    xKey="state",
    testId,
    ...rest
}) => {
    const height = rest.height || chartsHeight;
    const classes = useStyles({
        boxShadow: rest.boxShadow || defaultBoxShadow,
        height,
        maxWidth: rest.maxWidth || 400,
        minWidth: rest.minWidth || 400,
        legendGroup,
    });

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const [params] = useSetQuery();
    const [controller] = useState(() => new AbortController());
    const [docDate, setDocDate] = useState({});

    const { loading, data } = useSelector(({ emeaSummaries }) => emeaSummaries?.[subtype] || {});
    const [total, setTotal] = useState(0);
    const pageFromUrl = useMemo(() => location?.pathname.split('/')[4], [location?.pathname]);

    const currency = useMemo(() => localStorage.getItem("currency") || "USD", [localStorage.getItem("currency")]);

    const { locations, customers, sofcs, BU } = useEMEAGlobalContext();

    const documentDate = useMemo(() => {
        return getDocumentDate(pageFromUrl, params.documentDate)
    }, [params.documentDate, pageFromUrl]);

    useEffect(() => {
        if (data) {
            setTotal(data.reduce((a, b) => a + b.totalCount, 0));
        }
    }, [data]);

    const refreshChart = (newDocDate) => {
        const docDateFromFilter = monthFilter ? (newDocDate || getInitialDateobj(documentDate)) : documentDate;
        setDocDate(docDateFromFilter);
        const payload = {
            region: "EMEA",
            subtype,
            businessUnits: BU,
            sites: locations,
            documentDate: docDateFromFilter,
            customerOrSalesOffice: [...customers, ...sofcs],
            currency,
            ...excludedFilters
        };

        dispatch(emeaSummaryService({ payload, controller }));
    }

    useDeepCompareEffect(() => {
        if (refresh && customDocDate) {
            refreshChart(customDocDate);
            setRefresh(false);
        }
    }, [refresh, customDocDate]);

    const refreshAction = () => {
        refreshChart(docDate);
    }

    useDeepCompareEffect(() => {
        if (!params.loadView) {
            refreshChart(customDocDate);
        }
    }, [subtype, locations, sofcs, documentDate, customers, BU, params.loadView, currency]);

    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, []);

    const chartProps = {
        ...rest,
        data: data || [],
        loading,
        height: height - 100,
        xKey,
        keys: rest.keys || ["totalCount"],
        yKey: "totalCount",
        themeType: "light",
        trendline,
        yLabel,
        subtype,
        legend,
        legendGroup,
        testId
    };

    return (
        <Card className={classes.chartCard} data-testid="chartscard">
            <CardHeader
                action={
                    <>
                        {showMonthFilter && <EMEASelectMonthFilter dates={documentDate} onChange={refreshChart} />}
                        <IconButton data-testid={`${testId}-refresh`} size="small" onClick={() => refreshAction()}>
                            <RefreshOutlined fontSize="small" />
                        </IconButton>
                    </>
                }
                title={<Typography variant="h6" data-testid={`${testId}-title`} >{t(title)}</Typography>}
                subheader={
                    showTotal && (
                        <Typography variant="body2" data-testid={`${testId}-total`}>
                            {t("total_of", { total: formatLocaleNumber(total) || 0, currency: unitOfTotal })}
                        </Typography>
                    )
                }
            />
            {chart === "trendline" && <TrendLineChart {...chartProps} />}
            {chart === "pie" && <DonutChart {...chartProps} />}
            {chart === "treemap" && <TreeMap {...chartProps} xKey="stateDesc" />}
        </Card>
    );
};

export default EMEAChartCard;
