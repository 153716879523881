import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import EMEAChartCard from './EMEA_Common/EMEAChartCard';
import { useStyles } from './EMEA_Common/EMEAKPIsWrapper';
import useSetQuery, { useMultiSetQuery } from 'customHooks/useQuery';

export default function EMEAInvoiceKPIs() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [params] = useSetQuery();
  // const setMultiParams = useMultiSetQuery();

  // const [innerWidth] = useState(() => window.innerWidth - window.innerWidth / 10 * 1.5);
  const [currUnit, setCurrUnit] = useState(() => localStorage.getItem("currency") || "USD");

  const additionalFilter = {
    orderType: "ORDER_ENTRY"
  }

  // const setTreemapDetails = useCallback((filters) => {
  //   setMultiParams({
  //       emeaFilters: JSON.stringify(filters),
  //       isHyperlink: true
  //   });
  // }, [params]);

  const charts = [
    {
      title: `${t("invoices_distribution")} - ${t("product_structure")}`,
      subtype: "SUBTYPE_EMEA_INVOICES_TOP_FIVE_PRODUCT_STRUCTURE",
      chart: "treemap",
      yLabel: t('order_value_currency', { currency: currUnit }),
      colorPalette: "set6",
      legend: true,
      colorBy: "xKey",
      displayKey: "stateDesc",
      tooltipKey: "state",
      showXAxisLabel: false,
      maxWidth: 1600,
      minWidth: 1600,
      xKey: "state",
      yKey: "totalCount",
      // setDetails: (data) => {
      //   setTreemapDetails({
      //     materialHierarchyDesc: data,
      //     itemStatusBucketList: ["INVOICED"]
      //   })
      // }
    }, {
      title: `${t("invoices_distribution")} - ${t("sales_structure")}`,
      subtype: "SUBTYPE_EMEA_INVOICES_BY_COUNTRY_ID",
      chart: "treemap",
      yLabel: t('order_value_currency', { currency: currUnit }),
      colorPalette: "set6",
      legend: true,
      colorBy: "xKey",
      displayKey: "stateDesc",
      tooltipKey: "state",
      showXAxisLabel: false,
      maxWidth: 1600,
      minWidth: 1600,
      xKey: "state",
      yKey: "totalCount",
      // setDetails: (data) => {
      //   setTreemapDetails({
      //     materialHierarchyDesc: data,
      //     itemStatusBucketList: ["INVOICED"]
      //   })
      // }
    }, {
      title: "top_five_invoices_per_plant",
      subtype: "SUBTYPE_EMEA_INVOICES_TOP_FIVE_PLANT",
      chart: "pie",
      tooltipKey: "state",
      displayKey: "state",
      tooltipExclusion: ["other"],
      // setDetails: (data) => {
      //     setDetails({ key: "orderReasonCodeList", data, type: "array" })
      // },
      // tooltipEvent: tooltipEventTopReason,
      testId: "top-invoices-per-plant-chart"
    }
  ]

  useEffect(() => {
    setCurrUnit(localStorage.getItem("currency") || "USD");
  }, [params.currency]);

  return (
    <Card className={classes.root} data-testid="invoice-kpis-wrapper">
      <CardHeader 
        title={<Typography className={classes.title} variant="h5"> {t("live_status_text")} - {t("invoicing_information")} </Typography>}
      />
      <CardContent className={classes.cardcontent}>
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          {charts.map((d) => (
            <Grid item key={d.subtype} p={1}>
              <EMEAChartCard {...d} excludedFilters={additionalFilter} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )

}  