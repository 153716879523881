import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useDeepCompareEffect } from 'react-use';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useEMEAGlobalContext } from 'context/EMEAGlobalContext';
import EmeaTopMaterialSKUTable from "./EmeaTopMaterialSKUTable";
import EMEARejectionReasonsChart from "./EMEARejectionReasonsChart";
import { findStatusObject } from "./EMEAOpenOrdersStatus";
import { emeaSummaryService } from 'reduxLib/services/EmeaServices/emeaSummaryService';
import useSetQuery from "customHooks/useQuery";
import find from 'lodash/find';

const useStyles = makeStyles((theme) => ({
  main: {
    backgroundColor: theme.palette?.primary?.secondaryBase,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette?.card?.border}`,
    width: '48%',
    [theme.breakpoints.down('sm')]:{
      padding: theme.spacing(2),
    }
  },
  expandedMain: {
    backgroundColor: theme.palette?.primary?.main,
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette?.card?.border}`,
    width: '48%',
    [theme.breakpoints.down('sm')]:{
      padding: theme.spacing(2),
    }
  },
  grid: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette?.tooltip?.main,
    color: theme.palette?.white,
    borderRadius: theme.spacing(1),
    textAlign: 'center',
    width: '48%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]:{
      padding: `${theme.spacing(2)}px ${theme.spacing(0.5)}px`,
    }
  },
  linkGrid: {
    justifyContent: 'center',
    color: theme.palette?.link?.primary,
    marginTop: theme.spacing(1)
  },
  linkText: {
    textDecoration: 'underline',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  box: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3),
    backgroundColor: theme.palette?.primary?.main,
    borderRadius: theme.spacing(1)
  },
  title: {
    marginBottom: theme.spacing(2)
  }
}));

const EMEAOpenFollowUp = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [{ loadView }] = useSetQuery();

  const [controller] = useState(() => new AbortController());
  const [expandRejections, setExpandRejections] = useState(false);
  const [rejectionCount, setRejectionCount] = useState();
  const [rejectionPercent, setRejectionPercent] = useState();
  const rejections_loading = useSelector(({ emeaSummaries }) => emeaSummaries?.["SUBTYPE_EMEA_OPEN_ORDERS_ITEM_STATUS"]?.loading);
  const rejections_data = useSelector(({ emeaSummaries }) => emeaSummaries?.["SUBTYPE_EMEA_OPEN_ORDERS_ITEM_STATUS"]?.data || []);

  const {
    locations,
    customers = [],
    sofcs = [],
    BU,
    documentDate
  } = useEMEAGlobalContext();

  const getStatusKPI = ({ subType }) => {
    const payload = {
      region: "EMEA",
      subtype: subType,
      businessUnits: BU,
      sites: locations,
      documentDate : documentDate,
      customerOrSalesOffice: [...customers, ...sofcs],
      currency: localStorage.getItem("currency") || "USD",
      orderType: "ORDER_ENTRY"
    }
    dispatch(emeaSummaryService({ payload, controller }));
  }

  const blocks = ["local", "development"].includes(process.env.REACT_APP_ENV) ? [
    {
      title: t('cuts'),
      value: "-",
      percent: "-",
      expand: false
    },
    {
      title: t('rejections'),
      value: rejectionCount,
      percent: rejectionPercent,
      expand: true
    }
  ] : [ 
    {
      title: t('rejections'),
      value: rejectionCount,
      percent: rejectionPercent,
      expand: true
    }
  ]

  const calcPercent = () => {
    const sum = rejections_data.reduce((a, b) => a + b?.totalCount , 0);
    const obj = find(rejections_data, ['state', 'REJECTED']);
    const percentage = obj?.totalCount ? ((obj?.totalCount / sum) * 100) : 0;
    return percentage.toFixed(2);
  }

  useDeepCompareEffect(() => {
    const total = rejections_loading ? "..." : rejections_data?.length ? findStatusObject(rejections_data, "REJECTED") : "-";
    const percent = rejections_loading ? "..." : rejections_data?.length ? calcPercent() : "-";
    setRejectionCount(total);
    setRejectionPercent(percent);
  }, [rejections_data, rejections_loading])

  useDeepCompareEffect(() => {
    getStatusKPI({ subType: "SUBTYPE_EMEA_OPEN_ORDERS_ITEM_STATUS" });
  }, [locations, sofcs, documentDate, customers, BU, localStorage.getItem("currency")]);

  useEffect(() => {
    return () => {
      controller.abort();
    }
  }, []);

  return (
    <>
      <Grid container justifyContent="space-between" data-testid="open-follow-up">
        {blocks.map(item =>
          <Box className={(expandRejections && item.expand) ? classes.expandedMain : classes.main}>
            <Grid container alignItems="center">
              <Typography variant="h6"> {t('open_follow_up')} &nbsp; </Typography>
              <Typography variant="body1"> {` - ${item.title}`}</Typography>
            </Grid>
            <Grid container justifyContent="space-between">
              <Box className={classes.grid}>
                <Typography variant="h6"> {item.value} </Typography>
                <Typography variant="body1"> {item.title} </Typography>
              </Box>
              <Box className={classes.grid}>
                <Typography variant="h6"> {item.percent} % </Typography>
                <Typography variant="body1"> {item.title} % </Typography>
              </Box>
            </Grid>
            {item.expand && <Grid container className={classes.linkGrid}>
              <Typography variant="body1" onClick={() => setExpandRejections(!expandRejections)} className={classes.linkText} data-testid="expand-button">
                {expandRejections ? t('collapse') : t('expand')} {t('order_rejections')}
                {expandRejections ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Typography>
            </Grid>}
          </Box>
        )}
      </Grid>
      {expandRejections && <Box className={classes.box}>
        <Typography variant="h6" className={classes.title}> {t('live_status_text')} - {t('rejections')} </Typography>
        <EMEARejectionReasonsChart />
        <EmeaTopMaterialSKUTable />
      </Box>
      }
    </>
  )

}

export default EMEAOpenFollowUp;