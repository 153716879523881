import { useState } from "react";
import useSetQuery from "./useQuery";
import { useDispatch } from "react-redux";
import { generateFilterPayload } from "helpers/emeaHelpers";
// import { generateFilterPayload as generateFilterPayloadLao } from "helpers/laoHelpers";
import { isEmpty } from "lodash";
import { useEMEAGlobalContext } from "context/EMEAGlobalContext";
import { useLAOGlobalContext } from "context/LAOGlobalContext";
import { useDeepCompareEffect } from "react-use";

export const useRefreshTable = ({
    fetchEndPoint,
    tableName,
    type,
    controller,
    getTableData,
    tableBody,
    setPage: setParentPageSize
}) => {
    const {
        locations,
        customers,
        sofcs,
        BU,
        documentDate
    } = useEMEAGlobalContext();

    const dispatch = useDispatch();
    const [params] = useSetQuery();
    const [pageParams, setPageParams] = useState({ page: 0, pageSize: 15 });

    const tableFilters = params.emeaFilters;

    const refreshTable = ({ pageSize, page }) => {
        const updatedFilters = isEmpty(tableFilters?.originSites) ? { ...tableFilters, originSites: locations } : tableFilters;

        const reqBody = {
            controller,
            body: {
                fetchEndPoint,
                tablebody: {
                    ...tableBody,
                    ...updatedFilters,
                    documentDate,
                    businessUnits: BU,
                    customerOrSalesOffice: [...customers, ...sofcs]
                },
                pageSize,
                page,
                tableName,
                type
            },
            language: localStorage.getItem('i18nextLng')
        }
        dispatch(getTableData(reqBody));
    }

    useDeepCompareEffect(() => {
        if (!params.loadView) {
            refreshTable({ ...pageParams, page: 0 });
            setParentPageSize(0);
        }
    }, [tableFilters, documentDate, BU, customers, sofcs, locations, params.loadView]);

    const updatePageParams = ({ page, pageSize }) => {
        setPageParams({ page, pageSize });
        refreshTable({ page, pageSize });
    }

    return [updatePageParams, refreshTable]
}

export const useRefreshTableLAO = ({
    fetchEndPoint,
    tableName,
    type,
    controller,
    getTableData,
    subType,
    defaultFilters,
    setPage: setParentPageSize
}) => {
    const {
        locations,
        BU,
        language
    } = useLAOGlobalContext();

    const tableBody = {
        region: 'LAO',
        searchType: subType,
        ...(generateFilterPayload(defaultFilters)),
    };

    const dispatch = useDispatch();
    const [params] = useSetQuery();
    const [pageParams, setPageParams] = useState({ page: 0, pageSize: 15 });

    const tableFilters = params.laoFilters;
    const sites = subType === 'EXPORT_ORDER_DETAILS' ? 'originSites' : 'destSites'

    const refreshTable = ({ pageSize, page }) => {
        const updatedFilters = isEmpty(tableFilters?.originSites) ? { ...tableFilters, [sites]: locations } : tableFilters;

        const reqBody = {
            controller,
            body: {
                fetchEndPoint,
                tablebody: {
                    ...tableBody,
                    ...updatedFilters,
                    businessUnits: BU
                },
                pageSize,
                page,
                tableName,
                type
            },
            language: localStorage.getItem('i18nextLng')
        }
        dispatch(getTableData(reqBody));
    }

    useDeepCompareEffect(() => {
        if (!params.loadView) {
            refreshTable({ ...pageParams, page: 0 });
            setParentPageSize(0);
        }
    }, [tableFilters, BU, locations, params.loadView, language]);

    const updatePageParams = ({ page, pageSize }) => {
        setPageParams({ page, pageSize });
        refreshTable({ page, pageSize });
    }

    return [updatePageParams, refreshTable]
}

