import React, { useEffect, useState } from 'react';
import { useDeepCompareEffect } from 'react-use';
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MaterialTable from 'material-table'
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import TablePagination from "@material-ui/core/TablePagination";
import LinearProgress from '@material-ui/core/LinearProgress';
import TablePaginationActions from "components/common/Table/TablePaginationActions";
import { laoTableOptions } from 'helpers/tableStyleOverride';
import { tableStyles } from 'theme';
import useScrollInfo from 'theme/layouts/ScrollHelper';
import useSetQuery from 'customHooks/useQuery';
import isEmpty from 'lodash/isEmpty';
import differenceBy from 'lodash/differenceBy'
import cloneDeep from 'lodash/cloneDeep';
import LaoTableActionsWrapper from './LaoTableActionsWrapper';
import LaoFilterBox from './LaoFilterBox';
import { laoExpandDetailsColumns } from 'reduxLib/constdata/LAO/laoOrderDetailsColumns';
import { getLaoTableData } from 'reduxLib/services/LaoServices/laoTableServices';
import { useRefreshTableLAO } from 'customHooks/useTableQuery';
import Snack from "components/common/Helpers/Snack";
import DetailsTable from 'components/common/Table/DetailsTable';
import { laoImportOrderDetailsFilters, laoOrderDetailsFilters } from 'reduxLib/constdata/filters';
import { laoImportExpandDetailsColumns } from 'reduxLib/constdata/LAO/laoImportOrderDetailsColumns';

const useStylesTable = makeStyles(tableStyles);
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTablePagination-selectIcon': {
      color: "inherit"
    },
    '& .MuiTableFooter-root': {
      backgroundColor: theme.palette.secondary.base
    },
    width: '100%',  
    '& .MuiTableCell-body': {
      backgroundColor: theme.palette.primary.base
    }  
  },
  spacer: {
    flex: "1 1 auto"
  },
  progress: {
    backgroundColor: theme.palette?.text?.primary
  },
  noData: {
    textAlign: 'center',
    position: 'relative',
  },
  laoTable: {
    marginTop: theme.spacing(3),
    '& div::-webkit-scrollbar': {
      height: `${theme.spacing(1.5)}px !important`,
      width: `${theme.spacing(1.5)}px !important`,
    },
    '& div::-webkit-scrollbar-track': {
      border: `5px solid ${theme.palette.primary.base}`,
      borderRadius: 0,
      background: theme.palette.secondary.base
    },
    '& div::-webkit-scrollbar-thumb': {
      background: theme.palette.secondary.base
    },
    '& .MuiPaper-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiPaper-root > div': {
      borderTopLeftRadius: theme.spacing(3),
    }
  }
}));

const LaoTable = (props) => {
  const {
    tableRef,
    columns,
    tableName,
    subType,
    filterExclusionArray,
    searchText,
    filterType,
    fetchEndPoint
  } = props;
  const classes = useStyles();
  const tableClasses = useStylesTable();
  const { t } = useTranslation();
  const [paramsLao, setParamsLao] = useSetQuery();

  const type = 'lao';

  const laoTableData = useSelector(({ laoTable }) => laoTable?.[type]?.[tableName] || {});
  const { activeView } = useSelector(({ laoViews: { activeView } }) => ({ activeView }));

  const { data, status, loading } = laoTableData;


  const [columnsOrderLao, setcolumnsOrderLao] = useState([]);
  const [unTranslateColumn, setUnTranslateColumn] = useState([]);
  const [controller] = useState(() => new AbortController());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableData, setTableData] = useState({});
  const { items, totalRecords } = tableData;
  const [, setRef, ref] = useScrollInfo();
  const [snack, setSnack] = React.useState({
    open: false,
    severity: "error",
    message: null
  });
  const [updatePageParamsLao, refreshTableLao] = useRefreshTableLAO({
    fetchEndPoint,
    tableName,
    type,
    controller,
    subType,
    defaultFilters: tableName === "importOrderDetails" ? laoImportOrderDetailsFilters : laoOrderDetailsFilters,
    setPage,
    getTableData: getLaoTableData,
    region: 'LAO'
  });

  const executeScrollLao = () => {
    return ref?.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  useEffect(() => {
    if (status?.status === "error") {
      setSnack({
        open: true,
        severity: status.status,
        message: t(status.message)
      });
    }
  }, [status])

  useEffect(() => {
    if (paramsLao.isHyperlink) {
      executeScrollLao();
      setParamsLao({ key: "isHyperlink", value: undefined })
    }
  }, [paramsLao.isHyperlink]);


  const handleChangePageLao = (event, newPage) => {
    setPage(newPage);
    updatePageParamsLao({ pageSize: rowsPerPage, page: newPage });
  };

  const handleChangeRowsPerPageLao = (e) => {
    const pageSize = parseInt(e.target.value, 10)
    setRowsPerPage(pageSize);
    setPage(0);
    updatePageParamsLao({ pageSize: pageSize, page: 0 });
  };

  const updateColumnsLao = (columns) => {
    let col = columns.map(d => {
      return {
        ...d,
      title: t(d.title)
      }
    })
    setcolumnsOrderLao(col);
  }

  const clearAllFiltersLao = () => {
    setParamsLao({ key: "laoFilters", value: {} })
  };

  useDeepCompareEffect(() => {
    if (!isEmpty(activeView.columns)) {
      const actualColumns = columns.columnOrder;
      let temp = {};
      actualColumns.map(d => {
        temp[d.field] = d;
      });
      let viewColumnObject = activeView.columns;
      viewColumnObject = viewColumnObject.map(d => {
        return {
          ...d,
          ...(temp[d.field]),
          title: t(d.title),
        }
      })
      const newDifferenceColumns = isEmpty(activeView.columns) ? [] : differenceBy(columns.columnOrder, activeView.columns, 'field');

      if (newDifferenceColumns.length) {
        const translatedColumns = newDifferenceColumns.map(item => {
          return {
            ...item,
            title: t(item.title)
          }
        });
        viewColumnObject = [...viewColumnObject, ...translatedColumns];
      }
      setUnTranslateColumn([...activeView.columns, ...newDifferenceColumns])
      setcolumnsOrderLao(viewColumnObject);
    }
  }, [activeView.columns]);

  useDeepCompareEffect(() => {
    const finalColumnsList = columns.columnOrder;
    let tempObj = {};
    columns.columnOrder.map(d => {
      tempObj[d.field] = d;
    });
    let translatedColumns = finalColumnsList?.map(item => {
      return {
        ...tempObj[item.field],
        ...item,
        title: t(item.title),
      }
    });
    setUnTranslateColumn(finalColumnsList);
    setcolumnsOrderLao(translatedColumns);
  }, [localStorage.getItem('i18nextLng'), columns.columnOrder]);

  useDeepCompareEffect(() => {
    const tempTableData = data?.orders?.map(d => {
      return {
        ...d,
        ...columns.columnConfiguration(d)
      }
    }) || [];
    setTableData({ ...(data || {}), items: tempTableData });
  }, [data])

  useEffect(() => {
    return () => {
      controller.abort();
    }
  }, []);

  return (
    <Grid className={{
      [tableClasses.root]: true,
      [classes.root]: true
    }}>
      <LaoTableActionsWrapper
        title={t('order_details')}
        tableName={tableName}
        tableRef={tableRef}
        refreshTable={() => refreshTableLao({ pageSize: rowsPerPage, page })}
        filterType={filterType}
        type={type}
        subtype={tableName}
        filterExclusionArray={filterExclusionArray}
        searchText={searchText}
        columns={unTranslateColumn}
        totalRecords={totalRecords}
        fetchEndPoint={fetchEndPoint}
        columnsAction={updateColumnsLao}
        clearAllFilters={clearAllFiltersLao}
      />
      <div id="scrollIntoView" ref={setRef} />
      <LaoFilterBox
        tableName={tableName}
        type={type}
        filterBody={tableName === "importOrderDetails" ? laoImportOrderDetailsFilters : laoOrderDetailsFilters}
        clearAllFilters={clearAllFiltersLao}
        columns={unTranslateColumn}
      />
      <Snack {...snack} handleClose={() => setSnack({ open: false })} />
      <Grid data-testid="laoTable" className={classes.laoTable}>
        <MaterialTable
          isLoading={loading}
          key={items?.length}
          localization={{
            body: {
              emptyDataSourceMessage: <div className={classes.noData}>
                {
                  loading ?
                    t("please_wait_while_the_data_is_loading") :
                    t("no_records_to_display")
                }
              </div>
            }
          }}
          tableRef={tableRef}
          components={{
            Container: props_ => <Paper {...props_} elevation={0} />,
            OverlayLoading: props_ => <LinearProgress />,
            Pagination: props_ => (<TablePagination
              showFirstButton
              showLastButton
              rowsPerPageOptions={[10, 30, 50]}
              component="div"
              count={totalRecords || 0}
              page={page}
              onChangePage={handleChangePageLao}
              rowsPerPage={rowsPerPage}
              classes={classes}
              onChangeRowsPerPage={handleChangeRowsPerPageLao}
              ActionsComponent={TablePaginationActions}
              labelRowsPerPage={t("rows_per_page")}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('of')} ${count} ${t('records')}`}
              SelectProps={{ 'data-testid': 'rowsPerPageSelect', SelectDisplayProps: { 'data-testid': "rowsOption" } }}
              data-testid="pagination"
            />)
          }}
          columns={cloneDeep(columnsOrderLao)}
          data={items}
          options={{ ...laoTableOptions, selection: false, pageSize: rowsPerPage }}
          detailPanel={
            [
              () => ({
                render: rowData_ => <DetailsTable 
                  data={rowData_?.orderItems}
                  columns={tableName === "importOrderDetails" ? laoImportExpandDetailsColumns : laoExpandDetailsColumns} 
                />
              })
            ]
          }
        />
      </Grid>
    </Grid>
  );
}

export default LaoTable;