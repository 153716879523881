import React from "react";
import { useTranslation } from "react-i18next";
import { useDeepCompareEffect } from "react-use";
import { connect } from "react-redux";
import { useJsonToCsv } from 'react-json-csv';
import { json2csv } from 'json-2-csv';
import filesaver from 'file-saver';
import moment from "moment-timezone";
import get from "lodash/get";
import concat from "lodash/concat";
import { resetDownload } from "reduxLib/services";
import { generateDefaultData } from "helpers";
import Snack from "../Helpers/Snack";

export const DownloadManager = (props) => {
    const { t } = useTranslation();
    const { downloads, resetDownload } = props;

    const { saveAsCsv } = useJsonToCsv();

    const date = moment(new Date()).format("MM-DD-YYYY");

    const [snack, setSnack] = React.useState({
        open: false,
        severity: null,
        message: null
    });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnack({
            ...snack,
            open: false
        });
    };
    // bug-39124 translation for reason code for single and multiple reason codes
    const getReasonCode = (value) => {
        if (value?.includes("|")) {
            let arr = value.split("|");
            let result = arr.map(item => t(item.trim())?.replace(",", ''));
            return result.join(" | ");
        } else {
            return t(value?.trim())?.replace(",", '')
        }
    }

    const saveAs = ({ data, filename, type }) => {
        const blob = new Blob([data], { type });
        filesaver.saveAs(blob, filename);
    };

    useDeepCompareEffect(() => {
        Object.keys(downloads).map(type => {
            const filenames = {
                network: `${t('network_table')}_${type}_${date}`,
                orders: `${t('order_management')}_${type}_${date}`,
                transport: `${t('transportation')}_${type}_${date}`,
                distinbound: `${t('distribution_inbound')}_${type}_${date}`,
                distoutbound: `${t('distribution_outbound')}_${type}_${date}`,
                osmShipments: `${t('OSM_Shipments')}_${type}_${date}`,
                custReturns: `${t('customer_returns')}_${type}_${date}`,
                openOrders: `${t('open_orders')}_${type}_${date}`,
                cutsAndRejections: `${t('order_cuts_and_rejections')}_${type}_${date}`,
                exportOrderDetails: `${t('exportOrder')}_${type}_${date}`,
                importOrderDetails: `${t('importOrder')}_${type}_${date}`,
            }
            if (type) {
                Object.keys(downloads?.[type]).map(subtype => {
                    const { status, data, process, detailsProcess, itemInfoRequired } = downloads[type][subtype];
                    let filteredColumns = itemInfoRequired ? [...get(downloads[type][subtype], "columns", []), ...get(downloads[type][subtype], "detailsColumns", [])] : [...get(downloads[type][subtype], "columns", [])];
                    filteredColumns = filteredColumns.filter(d => !d.hidden);

                    if (status === "Success" && data) {
                        if (filteredColumns) {
                            let fields = {};
                            let colHeaders = [];
                            filteredColumns.map(d => {
                                if (d.field === "slno") return;
                                if (type === 'emea' && d.field === "emeaOrderStatusHealth") return;
                                fields = {
                                    ...fields,
                                    [d.field]: t(d.title)
                                };
                                colHeaders.push(d?.field);
                                return d;
                            });
                            const processed = data.map(d => {
                                let data1 = {
                                    ...generateDefaultData(filteredColumns),
                                    ...d,
                                    ...process(d),
                                    ...detailsProcess(d)
                                };
                                let processed1 = {};

                                Object.keys(data1).map(item => {
                                    try {
                                        if (typeof data1[item] === "string") {
                                            processed1[item] = data1[item].replace(/[,\s]+|[,\s]+/g, ' ');
                                        } else {
                                            processed1[item] = data1[item];
                                        }
                                    } catch (e) {
                                        processed1[item] = data1[item];
                                    }
                                    return item;
                                });
                                // bug-39124 translation for the keys in the below field
                                if (type === 'emea') {
                                    return {
                                        ...processed1,
                                        "materialGroupNum": processed1["materialGroupNum"]?.trim().length ? '=' + '"' + processed1["materialGroupNum"] + '"' : "-",
                                        "distributionChannel": processed1["distributionChannel"]?.trim().length ? '=' + '"' + processed1["distributionChannel"] + '"' : "-",
                                        "divisionName": processed1["divisionName"]?.trim().length ? '=' + '"' + processed1["divisionName"] + '"' : "-",
                                        "salesUnit": processed1["salesUnit"]?.trim().length ? '=' + '"' + processed1["salesUnit"] + '"'  : "-",
                                        "csrId": processed1["csrId"]?.trim().length ? '=' + '"' + processed1["csrId"] + '"' : "-",
                                        "shipToNum": processed1["shipToNum"]?.trim().length ? '=' + '"' + processed1["shipToNum"] + '"' : "-",
                                        "soldToNum": processed1["soldToNum"]?.trim().length ? '=' + '"' + processed1["soldToNum"] + '"' : "-",
                                        "customerPoNum": processed1["customerPoNum"]?.trim().length ? '=' + '"' + processed1["customerPoNum"] + '"' : "-",
                                    }
                                }
                                return {
                                    ...processed1,
                                    "liveLoadInd": t(processed1["liveLoadInd"]),
                                    "appointmentRequired": t(processed1["appointmentRequired"]),
                                    "orderOnHoldInd": t(processed1["orderOnHoldInd"]),
                                    "reasonCodeString": getReasonCode(processed1["reasonCodeString"]),
                                    "tmsRushIndicator": t(processed1["tmsRushIndicator"]),
                                    "loadingCompletedOnTime": t(processed1["loadingCompletedOnTime"]),
                                    "bolNum": processed1["bolNum"]?.trim().length ? `"\t"${processed1["bolNum"]}` : "-"
                                }
                            })
                            if (type === 'emea' || type === 'lao') {
                                const datanew = json2csv(concat(fields, processed), { delimiter: "field", keys: colHeaders, excelBOM: true, wrapBooleans: true, emptyFieldValue: '', prependHeader: false });
                                saveAs({ data: datanew, filename: filenames[subtype], type: 'text/csv;charset=utf-8' });
                            } else {
                                saveAsCsv({ data: processed || [], fields, filename: filenames[subtype] });
                            }
                        }
                        resetDownload({ type, subtype });
                        setSnack({ open: false })
                        setSnack({
                            open: true,
                            severity: "success",
                            message: t('download_successful')
                        });
                    } else if (status === "Failed") {
                        setSnack({
                            open: true,
                            severity: "error",
                            message: t('download_failed_msg')
                        })
                    }
                    return subtype;
                })
            }
            return null;
        })
    }, [downloads])

    return (
        <div data-testid="downloadmanager">
            <Snack {...snack} handleClose={handleClose} />
        </div>

    )

}

const mapStateToProps = (state, ownProps) => {
    return {
        downloads: get(state, `downloads`, {}),
    }
};

const mapDispatchToProps = {
    resetDownload
}

export default connect(mapStateToProps, mapDispatchToProps)(DownloadManager);