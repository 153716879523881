import moment from "moment-timezone";

export const KCRegions = ["NA", "EMEA", "LAO"];

// export const withLAORegion = ["NA", "EMEA", "LAO"];

export const businessUnits = ["CONSUMER", "PROFESSIONAL"];

export const viewByCuso = ["Customer", "Sales Office"];

export const emeaCustSeparator = '||';

export const salesOrgsbyBU = [
    {
        bu: businessUnits[1],
        salesOrgs: [{ value: "2821", region: "Canada" }, { value: "2811", region: "US" }],
    },
    {
        bu: businessUnits[0],
        salesOrgs: [{ value: "2820", region: "Canada" }, { value: "2810", region: "US" }],
    }
]
export const viewByConstants = {
    locations: {
        name: "shortName",
        id: "siteNum",
        type: "type"
    },
    customer: {
        name: "salesOffice",
        id: "salesOffice",
        type: "selectionType",
    },

}

export const freqMappings = {
    EVERY_15_MINS: "Every 15 mins",
    EVERY_30_MINS: "Every 30 mins",
    EVERY_60_MINS: "Every 1 hour",
    CUSTOM: "Custom"
}

export const distributionChannel = [{ value: "80", name: "domestic" }, { value: "90", name: "international" }]

export const osmLoadingInstructions = {
    D_DOWN_STACKING: "D Down Stacking",
    L_LOAD_LEVELING: "L Load Leveling",
    P_PACKED_LOADING: "P Packed Loading",
    R_ROTATE_TO_FILL_TAIL_GAP: "R Rotate To Fill Tail Gap",
    S_STAGGERED_LOAD: "S Staggered Load"
}

export const osmDiagramType = {
    CSR_CONVENTIONAL_STACK: 'CSR Conventional Stack Routine',
    // DIY_ORDER_LEBEL: 'DIY Order Label Routine', 
    FSR_FAILE_SAFE: 'FSR Fail-Safe Routine',
    MFS_MODIFIED_FAIL_SAFE: 'MFS Modified Fail-Safe Routine'
}

export const osmPrintTypes = {
    LSR: 'OSM_SUBTYPE_REPRINT_LSR',
    UCL: 'OSM_SUBTYPE_PRINT_UCC_LABEL',
    ADDR: 'OSM_SUBTYPE_PRINT_ADDR_LABEL'
}

export const osmPrintModes = {
    stack: "PRINT_PER_STACK_MODS",
    case: "PRINT_PER_CASE"
}

export const osmFilterExclusionArray = ["searchStringList", "matAvailDateCustom", "warehouseReadyDateTimeOriginTZCustom", "requestedDeliveryDateCustom", "loadStartDateTimeOriginTZCustom"];

export const kpiShortNames = {
    LOAD_DIAGRAM_SUBMISSION_PENDING: "ld_pending",
    LOAD_DIAGRAM_SUBMITTED: "ld_submitted",
    UCC_LABEL_PRINTED: "print_ucl",
    ADDRESS_LABEL_PRINTED: "print_addr_label",
    DOWNLOADED_TO_WMS: "download_to_wms",
    LOADING_STARTED: "loading_started",
    LOADING_ENDED: "loading_ended",
    SHIPMENT_COMPLETION: "shipment_compl",
    SHIPMENT_START: "shipment_start",
    SHIPMENT_END: "shipment_end"
}

export const defaultDocumentDate = {
    startTime: moment().subtract(90, "days").format("YYYY-MM-DD"),
    endTime: moment().format("YYYY-MM-DD"),
}

export const generateDefaultDocDate = (months = 3) => ({
    startTime: moment().subtract(months*30, "days").format("YYYY-MM-DD"),
    endTime: moment().format("YYYY-MM-DD"),
});

export const todayDocumentDate = {
    startTime: moment().format("YYYY-MM-DD"),
    endTime: moment().format("YYYY-MM-DD")
}

export const defaultOrderEntryDate = {
    startTime: moment().subtract(30, "days").format("YYYY-MM-DD"),
    endTime: moment().format("YYYY-MM-DD"),
}

export const getDocumentDate = (pageFromUrl, documentDate={}) => {
    if(pageFromUrl === 'orderEntry' || pageFromUrl === 'openOrders')
        return { ...defaultOrderEntryDate, ...documentDate };

    return { ...defaultDocumentDate, ...documentDate };
}

export const viewTableNames = {
    "custReturns": "EMEA_CUST_RETURNS",
    "openOrders": "EMEA_OPEN_ORDERS",
    "cutsAndRejections": "EMEA_ORDER_CUTS_AND_REJECTIONS"
}

export const laoViewTableNames = {
    "exportOrderDetails": "LAO_EXPORTS_ORDER_DETAILS",
    "importOrderDetails": "LAO_IMPORTS_ORDER_DETAILS"
}

export const laoSearchType = {
    "exportOrderDetails": "EXPORT_ORDER_DETAILS",
    "importOrderDetails": "IMPORT_ORDER_DETAILS"
}

export const emeaCountryCodes = {
    AD: "Andorra",
    AE: "United Arab Emirates",
    AF: "Afghanistan",
    AL: "Albania",
    AM: "Armenia",
    AO: "Angola",
    AQ: "Antarctica",
    AT: "Austria",
    AX: "Åland Islands",
    AZ: "Azerbaijan",
    BA: "Bosnia and Herzegovina",
    BE: "Belgium",
    BF: "Burkina Faso",
    BG: "Bulgaria",
    BH: "Bahrain",
    BI: "Burundi",
    BJ: "Benin",
    BL: "Saint Barthélemy",
    BV: "Bouvet Island",
    BW: "Botswana",
    BY: "Belarus",
    CD: "The Democratic Republic of the Congo",
    CF: "Central African Republic",
    CG: "Congo",
    CH: "Switzerland",
    CI: "Côte d'Ivoire",
    CK: "Cook Islands",
    CM: "Cameroon",
    CV: "Cabo Verde",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czechia",
    DE: "Germany",
    DJ: "Djibouti",
    DK: "Denmark",
    DZ: "Algeria",
    EE: "Estonia",
    EG: "Egypt",
    EH: "Western Sahara",
    ER: "Eritrea",
    ES: "Spain",
    ET: "Ethiopia",
    FI: "Finland",
    FO: "Faroe Islands",
    FR: "France",
    GA: "Gabon",
    GB: "United Kingdom of Great Britain and Northern Island",
    GE: "Georgia",
    GF: "French Guiana",
    GG: "Guernsey",
    GH: "Ghana",
    GI: "Gibralta",
    GL: "Greenland",
    GM: "Gambia",
    GN: "Guinea",
    GP: "Guadeloupe",
    GQ: "Equatorial Guinea",
    GR: "Greece",
    GW: "Guinea-Bissau",
    HM: "Heard Island and McDonald Islands",
    HR: "Croatia",
    HU: "Hungary",
    IE: "Ireland",
    IL: "Israel",
    IM: "Isle of Man",
    IQ: "Iraq",
    IR: "Iran",
    IS: "Iceland",
    IT: "Italy",
    IC: "Canary Island",
    JE: "Jersey",
    JO: "Jordan",
    KE: "Kenya",
    KG: "Kyrgyzstan",
    KM: "Comoros",
    KW: "Kuwait",
    KZ: "Kazakhstan",
    LB: "Lebanon",
    LI: "Liechtenstein",
    LR: "Liberia",
    LS: "Lesotho",
    LT: "Lithuania",
    LU: "Luxembourg",
    LV: "Latvia",
    LY: "Libya",
    MA: "Morocco",
    MC: "Monaco",
    MD: "Moldova",
    ME: "Montenegro",
    MF: "Collectivity of Saint Martin",
    MG: "Madagascar",
    MK: "North Macedonia",
    ML: "Mali",
    MQ: "Martinique",
    MR: "Mauritania",
    MT: "Malta",
    MU: "Mauritius",
    MW: "Malawi",
    MZ: "Mozambique",
    NA: "Namibia",
    NC: "New Caledonia",
    NE: "Niger",
    NG: "Nigeria",
    NL: "Netherlands",
    NO: "Norway",
    OM: "Oman",
    PF: "French Polynesia",
    PL: "Poland",
    PM: "Saint Pierre and Miquelon",
    PS: "State of Palestine",
    PT: "Portugal",
    QA: "Qatar",
    RE: "Réunion",
    RO: "Romania",
    RS: "Serbia",
    RU: "Russian Federation",
    RW: "Rwanda",
    SA: "Saudi Arabia",
    SC: "Seychelles",
    SD: "Sudan",
    SE: "Sweden",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    SI: "Slovenia",
    SK: "Slovakia",
    SL: "Sierra Leone",
    SM: "San Marino",
    SN: "Senegal",
    SO: "Somalia",
    SS: "South Sudan",
    ST: "Sao Tome and Principe",
    SX: "Sint Maarten",
    SY: "Syrian Arab Republic",
    SZ: "Swaziland",
    TD: "Chad",
    TF: "French Southern Territories",
    TG: "Togo",
    TJ: "Tajikistan",
    TM: "Turkmenistan",
    TN: "Tunisia",
    TR: "Turkey",
    TZ: "Tanzania",
    UA: "Ukraine",
    UG: "Uganda",
    UZ: "Uzbekistan",
    VA: "Holy See",
    WF: "Wallis and Futuna",
    YE: "Yemen",
    YT: "Mayotte",
    ZA: "South Africa",
    ZM: "Zambia",
    ZW: "Zimbabwe"
}
