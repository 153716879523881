import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { Route, withRouter } from 'react-router-dom';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import OktaConfig from "../configs/OktaConfig";

import Main from 'components/pages/Main';
import UpdateCSTRanking from './layouts/UpdateCSTRanking';
import Requests from './layouts/Requests';
import Home from 'components/pages/Home';
import Orders from 'components/pages/Orders';
import Transport from 'components/pages/Transport';
import Rootlayout from 'theme/layouts/Rootlayout';
import Distribution from 'components/pages/Distribution';
import RedirectManager from 'theme/layouts/RedirectManager';
// import SubRootLayout from 'theme/layouts/SubRootLayout';
import ShipmentDetails from 'components/pages/ShipmentDetails';
import AnalyticsReport from 'components/pages/AnalyticsReport';
import SelfAlerting from 'components/pages/SelfAlerting';
import SelfAlertingAll from 'components/pages/SelfAlertingAll';

// DOCC or OSM
import OSM from 'components/pages/OSM';
import OsmLsrReport from 'components/pages/OsmLsrReport';
import OsmStackDetails from 'components/pages/OsmStackDetails';

import RegionRootLayout from 'theme/layouts/RegionRootLayout';

// EMEA
import EMEAHome from './layouts/EMEA/EMEAHome';
import EMEAReturns from './layouts/EMEA/EMEAReturns';
import EMEAOrderEntry from './layouts/EMEA/EMEAOrderEntry';
import EMEAOpenOrders from './layouts/EMEA/EMEAOpenOrders';
import EMEAOpenOrdersInvoice from './layouts/EMEA/EMEAOpenOrdersInvoice';
import EMEAOrderCutsAndRejections from './layouts/EMEA/EMEAOrderCutsAndRejections';
import EMEAInvoices from './layouts/EMEA/EMEAInvoices';

// LAO
import LaoHome from './layouts/LAO/LaoHome';

import PageNotFound from 'components/pages/404';


const Routes = (props) => {

  const pages = {
    home: Home,
    orders: Orders,
    transport: Transport,
    distribution: Distribution
  }

  return (
    <Security {...OktaConfig}>
      <Switch>
        <Redirect
          exact
          from="/"
          to="/dashboard"
        />
        {/* <Redirect
          exact
          from="/dashboard"
          to="/redirect"
        /> */}
        <Route path='/callback' component={LoginCallback} />
        <SecureRoute
          path={"/redirect"}
          component={(props) => <RedirectManager {...props} />}
        />
        <SecureRoute path='/dashboard' exact component={Main} />
        <SecureRoute path='/updateCSTRanking' exact component={UpdateCSTRanking} />
        <SecureRoute
          path="/dashboard/EMEA/:tab/home"
          exact
          render={() => <RegionRootLayout component={<EMEAHome />} />}
        />
        <SecureRoute
          path="/dashboard/EMEA/:tab/returns"
          exact
          render={() => <RegionRootLayout component={<EMEAReturns />} />}
        />
        <SecureRoute
          path="/dashboard/EMEA/:tab/orderEntry"
          exact
          render={() => <RegionRootLayout component={<EMEAOrderEntry />} />}
        />
        <SecureRoute
          path="/dashboard/EMEA/:tab/openOrders"
          exact
          render={() => <RegionRootLayout component={<EMEAOpenOrders />} />}
        />
        <SecureRoute
          path="/dashboard/EMEA/:tab/openOrders/invoiced"
          exact
          render={() => <RegionRootLayout component={<EMEAOpenOrdersInvoice />} />}
        />
        <SecureRoute
          path="/dashboard/EMEA/:tab/orderCutsRejections"
          exact
          render={() => <RegionRootLayout component={<EMEAOrderCutsAndRejections />} />}
        />
        <SecureRoute
          path="/dashboard/EMEA/:tab/invoices"
          exact
          render={() => <RegionRootLayout component={<EMEAInvoices />} />}
        />
        <SecureRoute
          path="/dashboard/LAO/:tab/home"
          exact
          render={() =>  <RegionRootLayout component={<LaoHome />} />}
        />
        <SecureRoute
          path="/dashboard/:type/:showtabsby/:page(home|orders|distribution|transport)/:tabletype(inbound|outbound)?/shipment/:shipmentNum"
          exact
          render={(props) => {
            return <Rootlayout component={ShipmentDetails} location={props} hide={true} />
          }}
        />
        <SecureRoute
          path="/dashboard/:type/:showtabsby/:page(home|orders|distribution|transport)/:tabletype(inbound|outbound)?/alerts/:alertid/shipment/:shipmentNum"
          exact
          render={() => {
            return <Rootlayout component={ShipmentDetails} location={props} hide={true} />
          }}
        />
        <SecureRoute
          path="/dashboard/:type/:showtabsby/:page(home|orders|distribution|transport)/:tabletype(inbound|outbound)?/alerts/:alertid"
          exact
          render={() => {
            return <Rootlayout component={SelfAlerting} hide={true} />
          }}
        />
        <SecureRoute
          path="/dashboard/:type/:showtabsby/:page(home|orders|distribution|transport)/:tabletype(inbound|outbound)?/alerts"
          exact
          render={() => {
            return <Rootlayout component={SelfAlertingAll} hide={true} />
          }}
        />
        <SecureRoute
          path="/dashboard/:type/:showtabsby/:page(home|orders|distribution|transport)/:tabletype(inbound|outbound)?"
          render={(props) => {
            const {
              match: { params: pageparams }
            } = props;

            const { page } = pageparams;
            return <Rootlayout component={pages[page]} location={props} />
          }}
        />
        <SecureRoute
          path="/dashboard/:type/:showtabsby/analytics/:folder/:report"
          render={(props) => {
            return <Rootlayout component={AnalyticsReport} location={props} hide={true} />
          }}
        />
        <SecureRoute path='/requests' component={Requests} />
        <SecureRoute
          path="/dashboard/:type/:showtabsby/osm/:osmviewid/viewLsr/:origin/:shipmentNum"
          render={(props) => {
            return <Rootlayout component={OsmLsrReport} hide={true} location={props} />
          }}
        />
        <SecureRoute
          path="/dashboard/:type/:showtabsby/osm/:osmviewid/viewStackInfo/:origin/:shipmentNum"
          render={(props) => {
            return <Rootlayout component={OsmStackDetails} hide={true} location={props} />
          }}
        />
        <SecureRoute
          path="/dashboard/:type/:showtabsby/osm/:osmviewid?"
          render={(props) => {
            return <Rootlayout component={OSM} hide={true} location={props} />
          }}
        />
        
        <Route path='/not-found' component={PageNotFound} />
        <Redirect to="/not-found" />
      </Switch>
    </Security>
  );
}

export default withRouter(Routes);