import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slider from "react-slick";
import EMEAChartCard from './EMEA_Common/EMEAChartCard';
import useSetQuery, { useMultiSetQuery } from 'customHooks/useQuery';
import { useStyles } from './EMEA_Common/EMEAKPIsWrapper';
import { pwaSettings } from 'configs/pwaConstants';

export default function EMEAOpenOrdersKPIs() {
    const classes = useStyles();
    const { t } = useTranslation();
    const setMultiParams = useMultiSetQuery();
    const theme = useTheme();
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [params] = useSetQuery();

    const [innerWidth] = useState(() => window.innerWidth - window.innerWidth/10 * 1.5)
    const [unit, setUnit] = useState(() => localStorage.getItem("currency") || "USD");

    useEffect(() => {
        setUnit(localStorage.getItem("currency") || "USD");
    }, [params.currency]);

    const setDetailsTreemap = useCallback((filters) => {
        setMultiParams({
            emeaFilters: JSON.stringify(filters),
            isHyperlink: true
        });
    }, [params]);

    const setDetails = useCallback(({ key, data, type }) => {
        let payload = data.state;

        setMultiParams({
            emeaFilters: JSON.stringify({
                [key]: type === "array" ? [payload] : payload
            }),
            isHyperlink: true
        });
    }, [params]);

    const additionalFilters = {
        orderType: "ORDER_ENTRY"
    }

    const chartsTop = [
        {
            title: t("actual_and_expected_invoice_date_by_value"),
            subtype: "SUBTYPE_EMEA_OPEN_ORDERS_ACTUAL_AND_EXPECTED_INVOICE",
            chart: "trendline",
            yLabel: t('order_value_currency', { currency: unit }),
            colorBy: "xKey",
            colorPalette: "set9",
            trendline: true,
            showTotal: true,
            xAxisTitle: "Invoice Date",
            unitOfTotal: unit,
            defaultColor: "",
            legend: false,
            xKey: "stateDesc",
            displayKey: "stateDesc",
            tooltipKey: "state",
            showXAxisLabel: false,
            maxWidth: 920,
            minWidth: 920,
            trendLineKey: "totalCount2",
            tredlineStyle: "dashed",
            showBarLabel: false,
            monthFilter: true,
            showMonthFilter: true,
            legendType: "custom",
            legendGroup: true,
            tooltipOptions: {
                showTotal: false
            },
            legendGroupItems: [
                { type: 'circle', color: theme.palette.set9[0], label: t('actual_invoice'), key: "totalCount" },
                { type: 'dottedLine', color: "", label: t('expected_invoice'), key: "totalCount2" },
            ]
        }
    ];

    const chartsBottom = [
        {
            title: t("open_orders_distribution_product_structure_by_value"),
            subtype: "SUBTYPE_EMEA_OPEN_ORDERS_PRODUCT_STRUCTURE",
            chart: "treemap",
            yLabel: t('order_value_currency', { currency: unit }),
            colorPalette: "set6",
            legend: true,
            colorBy: "xKey",
            displayKey: "stateDesc",
            tooltipKey: "state",
            showXAxisLabel: false,
            maxWidth: 500,
            minWidth: 500,
            xKey: "state",
            yKey: "totalCount",
            setDetails: (data) => {
                setDetailsTreemap({
                    materialHierarchyDesc: data,
                    itemStatusBucketList: ["INVOICED", "UNDER_CREDIT_ANALYSIS", "DELIVERY_BLOCK", "DELIVERY", "TRANSPORTATION_PLAN"]
                })
            }
        },
        {
            title: t("order_status_req_del_date_rdd_by_value"),
            subtype: "SUBTYPE_EMEA_OPEN_ORDERS_RDD_STATUS",
            chart: "trendline",
            yLabel: t('order_value_currency', { currency: unit }),
            colorPalette: "set11",
            legend: true,
            unitOfTotal: unit,
            showTotal: true,
            displayKey: "stateDesc",
            tooltipKey: "stateDesc",
            colorBy: "xKey",
            xKey: "stateDesc",
            showXAxisLabel: false,
            setDetails: (data) => {
                setDetails({ key: "reqDelDateBucketList", data, type: "array" })
            }
        }
    ];

    return (
        <Card className={classes.root} data-testid="orkpiswrapper">
            <CardHeader
                title={<Typography className={classes.title} variant="h3" component={"h3"}>{t("live_status_text")}</Typography>}
            />
            {
                isMobile &&  <Slider {...pwaSettings} className={classes.slider}>
                    {
                        [...chartsTop, ...chartsBottom].map(d => {
                            return (
                                    <div>
                                        <EMEAChartCard {...d} maxWidth={innerWidth} minWidth={innerWidth} boxShadow="none" excludedFilters={additionalFilters} />
                                    </div>
                            )
                        })
                    }
                </Slider>
            }
            {
                !isMobile && <>
                    <CardContent p={0}>
                        <Grid container spacing={3} alignItems="center" justifyContent="center">
                            {chartsTop.map((d) => (
                                <Grid item key={d.subtype} p={1}>
                                    <EMEAChartCard {...d} excludedFilters={additionalFilters} />
                                </Grid>
                            ))
                            }
                        </Grid>
                    </CardContent>
                    <CardContent className={classes.cardcontent}>
                        <Grid container spacing={3} alignItems="center" justifyContent="center">
                            {chartsBottom.map((d) => (
                                <Grid item key={d.subtype} p={1}>
                                    <EMEAChartCard {...d} excludedFilters={additionalFilters} />
                                </Grid>
                            ))}
                        </Grid>
                    </CardContent>
                </>
            }
        </Card>
    );
}

