import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDeepCompareEffect } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import { emeaSummaryService } from 'reduxLib/services/EmeaServices/emeaSummaryService';
import { useEMEAGlobalContext } from 'context/EMEAGlobalContext';
import useSetQuery, { useMultiSetQuery } from "customHooks/useQuery";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(2.5),
    boxShadow: '0px 2px 4px 2px rgba(0,0,0,0.12)'
  },
  container: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1)
  },
  table: {
    borderRadius: theme.spacing(1)
  },
  noData: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '15vw'
  },
  materialLink: {
    width: 'fit-content',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.palette?.link?.primary
  }
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette?.background?.quaternaryBase,
    },
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette?.background?.drawer,
    }
  }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.background?.primaryBase,
    color: theme.palette.white,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontSize: 14
  },
  body: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  footer: {
    backgroundColor: theme.palette?.table?.footer,
    color: theme.palette.white,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textTransform: 'uppercase',
    borderBottom: `0px solid`
  }
}))(TableCell);

const EmeaTopMaterialSKUTable = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [{ loadView }] = useSetQuery();
  const setMultiQuery = useMultiSetQuery();
  const dispatch = useDispatch();
  const [controller] = useState(() => new AbortController());
  const subType = "SUBTYPE_EMEA_ORDER_REJECTION_MATERIALS_BY_VALUE";
  const kpi_loading = useSelector(({ emeaSummaries }) => emeaSummaries?.[subType]?.loading);
  const kpi_data = useSelector(({ emeaSummaries }) => emeaSummaries?.[subType]?.data || []);
  const kpi_error = useSelector(({ emeaSummaries }) => emeaSummaries?.[subType]?.error);
  const [total, setTotal] = useState(0);

  const {
    locations,
    customers = [],
    sofcs = [],
    BU,
    documentDate
  } = useEMEAGlobalContext();

  const refreshSKUKPI = () => {
    if (loadView) return null;

    const payload = {
      region: "EMEA",
      subtype: subType,
      businessUnits: BU,
      sites: locations,
      documentDate : documentDate,
      customerOrSalesOffice: [...customers, ...sofcs],
      currency: localStorage.getItem("currency") || "USD",
      orderType: "ORDER_ENTRY"
    }
    dispatch(emeaSummaryService({ payload, controller }));
  }

  const setHyperlinkFilters = ({ key, data }) => {
    setMultiQuery({
      emeaFilters: JSON.stringify({
          [key]: [data]
      }),
      isHyperlink: true
    });
  }

  useDeepCompareEffect(() => {
    refreshSKUKPI();
  }, [locations, sofcs, documentDate, customers, BU, localStorage.getItem("currency")]);

  useDeepCompareEffect(() => {
    if (!kpi_loading && kpi_data?.length) {
      const count = kpi_data.reduce((sum, curr) => sum + curr?.totalCount , 0);
      setTotal(count);
    } else {
      setTotal(0);
    }
  }, [kpi_data, kpi_loading])

  return (
    <Box mt={3} className={classes.root}>
      <Typography variant="body1"> {t('top_materials_sku')} </Typography>
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="top materials sku table" data-testid="material-sku-table">
          <TableHead>
            <TableRow>
              <StyledTableCell> {t('material_number')} </StyledTableCell>
              <StyledTableCell align="right"> {t('rejected_value')} </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {kpi_loading ? 
              <div className={classes.noData}> {t('please_wait_while_the_data_is_loading')} </div>
            : kpi_error ?
              <div className={classes.noData}> {t(kpi_error)} </div>
            : (kpi_data?.length === 0) ?
              <div className={classes.noData}> {t('no_data_found')} </div>
            : kpi_data.map((row) => (
              <StyledTableRow key={row.state}>
                <StyledTableCell component="th" scope="row"> 
                  <Typography variant="body2" className={classes.materialLink} onClick={() => setHyperlinkFilters({ key: "materialNumberList", data: row?.state })} data-testid={row.state}> 
                    {row?.state} 
                  </Typography>
                </StyledTableCell>
                <StyledTableCell align="right"> {row?.totalCount} {row?.currency} </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <StyledTableCell> {t('total')} </StyledTableCell>
              <StyledTableCell align="right"> {total} {localStorage.getItem("currency") || "USD"} </StyledTableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default EmeaTopMaterialSKUTable;