import React, { useCallback, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Filters from "components/common/Filters/index";
import EmeaViews from './EmeaViews';
import { downloadService } from 'reduxLib/services/downloadService';
import { ColumnManager } from 'components/common/Table/ColumnManager';
import Snack from 'components/common/Helpers/Snack';
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';
import FilterIcon from "assets/images/dark-filter-icon.svg";
import ExcelIcon from 'assets/images/excel.png';
import ViewsIcon from "assets/images/table-view-dark.svg";
import useSetQuery from 'customHooks/useQuery';
import { getEMEAFilterArgs } from 'helpers/emeaHelpers';
import { useEffect } from 'react';
import { getAllEMEAViews, resetEMEAViews } from 'reduxLib/services/EmeaServices/emeaViewsServices';
import { viewTableNames } from 'configs/appConstants';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  text: {
    color: theme.palette?.text?.primary
  },
  actions: {
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  }
}));

const EmeaTableActionsWrapper = ({
  title, tableName, tableRef,
  refreshTable, filterType, type,
  subtype, filterExclusionArray,
  searchText, columns, totalRecords,
  fetchEndPoint, columnsAction, clearAllFilters, defaultColumns
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const [controller] = useState(new AbortController());
  const [params, setParams] = useSetQuery();

  const emeaTableData = useSelector(({ emeaTable }) => emeaTable?.[type]?.[tableName] || {});
  const downloading = useSelector(({ downloads }) => downloads?.[type]?.[subtype]?.downloading);

  const { payload, loading } = emeaTableData;

  const [open, setOpen] = useState(false);
  const [viewsOpen, setViewsOpen] = useState(false);
  const [snack, setSnack] = React.useState({
    open: false,
    severity: null,
    message: null
  });

  useEffect(() => {
    dispatch(getAllEMEAViews({
      tableName: viewTableNames[tableName]
    }, controller));
    return () => {
      dispatch(resetEMEAViews({
        tableName: viewTableNames[tableName]
      }));
      controller.abort()
    }
  }, []);

  const customSaveFilters = (savePayload) => {
    const args = getEMEAFilterArgs(savePayload.filter);
    const customURLValues = {
      [
        args?.requestedDeliveryDateCustom ?
          "requestedDeliveryDate" : undefined
      ]: undefined,
      "deliveryBlocked": args?.deliveryBlocked?.[0] || null
    };
    setParams({
      key: "emeaFilters",
      value: {
        ...params.emeaFilters,
        ...args,
        ...customURLValues
      }
    })
  }

  const handleOpenFilters = useCallback(() => {
    setOpen((prev) => !prev);
    handleCloseViews();
  }, [open]);

  const handleCloseFilters = () => {
    setOpen(false);
  }

  const handleOpenViews = () => {
    setViewsOpen(e => !e);
    handleCloseFilters();
  };

  const handleCloseViews = () => {
    setViewsOpen(false);
  };

  const columnsOpenAction = () => {
    handleCloseViews();
    handleCloseFilters();
  }

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnack({
      ...snack,
      open: false
    });
  };

  const exportTable = () => {
    if (!downloading) {
      dispatch(downloadService({
        config: {
          totalRecords,
          body: payload?.body?.tablebody,
          url: fetchEndPoint,
          columns
        },
        emea: true,
        type,
        subtype,
        process: defaultColumns.columnConfiguration,
        detailsProcess: () => { }
      }));
      setSnack({
        open: true,
        severity: 'info',
        message: t("download_started_msg")
      });
    } else {
      setSnack({
        open: true,
        severity: 'warning',
        message: t("download_progress_msg")
      });
    }
  }

  return (
    <Grid container
      data-testid='emea-table-actions-wrapper'
      direction="row"
      justify="space-between"
      spacing={3}
      alignItems="center"
      className={classes.root}
    >
      <Grid container item xs={12} sm={12} md={7} xl={8} justify="flex-start" spacing={2} >
        {title && <Grid item  >
          <Typography variant="h3" data-testid={`${tableName}_tablename`} className={classes.text} > {title} </Typography>
        </Grid>}
      </Grid>

      <Grid container item xs={12} sm={12} md={5} xl={4} className={classes.actions}>
        <Grid item >
          <Box textAlign='center' aria-label="Manage Columns">
            <ColumnManager
              columns={columns}
              columnsAction={columnsAction}
              columnsOpenAction={columnsOpenAction}
            />
          </Box>
        </Grid>
        <Grid item >
          <Box textAlign='center' >
            <IconButton 
            className={classes.text} 
            data-testid="refreshTable"
             onClick={() => refreshTable(tableRef)} 
            disabled={loading}
            >
              <RefreshOutlined />
            </IconButton>
            {!isMobile && <Typography variant='body2'> {t('refresh')} </Typography>}
          </Box>
        </Grid>

        <Grid item>
          <Box textAlign='center'>
            <IconButton data-testid="exportTable" onClick={exportTable}>
              <img src={ExcelIcon} alt="Export" />
            </IconButton>
            {!isMobile && <Typography variant='body2'> {t('export')} </Typography>}
          </Box>
        </Grid>
        <Grid item>
          <ClickAwayListener onClickAway={handleCloseViews}>
            <div>
              <Box textAlign='center'>
                <IconButton data-testid="tableViews" size='small' onClick={handleOpenViews}>
                  <img src={ViewsIcon} alt="Views" />
                </IconButton>
                {!isMobile && <Typography variant='body2'> {t('views')} </Typography>}
              </Box>
              <EmeaViews open={viewsOpen} type={type} tableName={tableName} defaultColumns={defaultColumns} />
            </div>
          </ClickAwayListener>
        </Grid>
        <Grid item>
          <Box textAlign='center'>
            <IconButton data-testid="emeaFilters"
              onClick={handleOpenFilters}>
              <img src={FilterIcon} alt="Filter" />
            </IconButton>
            {!isMobile && <Typography variant='body2'> {t('filters')} </Typography>}
          </Box>
          <Filters
            filtertype={filterType}
            open={open}
            handleOpen={handleOpenFilters}
            type={type}
            subtype={subtype}
            customSaveFilters={customSaveFilters}
            clearAllFilters={clearAllFilters}
            exclude={filterExclusionArray}
            searchText={searchText}
          />
        </Grid>
      </Grid>

      <Snack {...snack} handleClose={handleSnackClose} />

    </Grid>
  )
}
export default EmeaTableActionsWrapper;