import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slider from "react-slick";
import qs from "query-string";
import EMEAChartCard from "components/EMEA/EMEA_Common/EMEAChartCard";
import { useLocation, useHistory } from "react-router-dom";
import useSetQuery, { useMultiSetQuery } from "customHooks/useQuery";
import { pwaSettings } from "configs/pwaConstants";
import { generateDefaultDocDate } from "configs/appConstants";
import { Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "auto",
        minHeight: theme.spacing(25),
        borderRadius: theme.spacing(1),
        background: theme.palette.card.default,
    },
    title: {
        marginRight: "auto",
        paddingBottom: theme.spacing(2),
        fontSize: theme.spacing(2),
        textTransform: "none",
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
        }
    },
    slider: {
        margin: `0px ${theme.spacing(3)}px ${theme.spacing(1)}px`,
        "& .slick-current": {
            display: "flex",
            justifyContent: "center"
        }
    },
    chartRejectedOrders:{
        justifyContent: "center"
    }
}));

const EMEAHome = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const setMultiQuery = useMultiSetQuery();
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const [innerWidth] = useState(() => window.innerWidth - window.innerWidth / 10 * 1.5)

    const [params] = useSetQuery();

    const [unit, setUnit] = useState(() => localStorage.getItem("currency") || "USD");

    useEffect(() => {
        setUnit(localStorage.getItem("currency") || "USD");
    }, [params.currency]);

    useEffect(() => {
        if (params.loadView) {
            setMultiQuery({
                documentDate: undefined,
                loadView: undefined
            });
        }
    }, [params.loadView, setMultiQuery]);

    const viewDetails = useCallback((page) => {
        const regx = new RegExp("[^\/]+$", "g");
        const currentParams = qs.parse(location.search);
        const pathname = location.pathname.replace(regx, page);

        let additionObj = {};
        if (page === "openOrders") {
            additionObj.documentDate = undefined;
        }

        history.replace({
            ...location,
            search: qs.stringify({
                ...currentParams,
                loadView: true,
                ...additionObj
            }),
            pathname
        });
    }, [location, history]);

    const hideEnv = process.env.REACT_APP_ENV === "production";

    const chartsData = useMemo(() => [
        {
            title: t("return_orders_by_value"),
            subtype: "SUBTYPE_EMEA_ORDER_RETURN_MONTH_ORDER_VALUE",
            chart: "trendline",
            unitOfTotal: unit,
            yLabel: t('order_value_currency', { currency: unit }),
            excludedFilters: { documentDate: null },
            trendline: true,
            showTotal: true,
            viewDetails: () => viewDetails("returns"),
            testId: "customer-return-orders-chart"
        },
        {
            title: t("order_entry_by_value"),
            subtype: "SUBTYPE_EMEA_ORDER_ENTRY_MONTH_ORDER_VALUE",
            chart: "trendline",
            unitOfTotal: unit,
            trendline: true,
            showTotal: true,
            yLabel: t('order_value_currency', { currency: unit }),
            excludedFilters: { documentDate: null },
            viewDetails: () => viewDetails("orderEntry"),
            testId: "order-entry-chart"
        },
        {
            title: t("order_status_req_del_date_rdd_by_value"),
            subtype: "SUBTYPE_EMEA_OPEN_ORDERS_RDD_STATUS",
            chart: "trendline",
            unitOfTotal: unit,
            showTotal: true,
            yLabel: t('order_value_currency', { currency: unit }),
            excludedFilters: {
                orderType: "ORDER_ENTRY",
                documentDate: generateDefaultDocDate(3)
            },
            displayKey: "stateDesc",
            tooltipKey: "stateDesc",
            colorBy: "xKey",
            xKey: "stateDesc",
            showXAxisLabel: false,
            viewDetails: () => viewDetails("openOrders"),
            colorPalette: "set11",
            testId: "open-orders-chart"
        },
        {
            title: t("rejected_orders_by_value"),
            subtype: "SUBTYPE_EMEA_ORDER_REJECTION_MONTH_ORDER_VALUE",
            chart: "trendline",
            unitOfTotal: unit,
            trendline: true,
            showTotal: true,
            yLabel: t('order_value_currency', { currency: unit }),
            excludedFilters: { documentDate: null },
            viewDetails: () => viewDetails("orderCutsRejections"),
            testId: "rejected-orders-chart"
        },
    ], [unit, hideEnv]);

    return (
        <>
            <Card className={classes.root}>
                <CardHeader
                    title={<Typography className={classes.title} variant="h3" component={"h3"} data-testid="live-status-text">{t("live_status_last_90days")}</Typography>}
                />
                {
                    isMobile && <>
                        <Slider {...pwaSettings} className={classes.slider}>
                            {
                                chartsData.map(d => {
                                    if (d.hideEnv) return null;
                                    return (
                                        <div>
                                            <EMEAChartCard
                                                {...d}
                                                key={d.subtype}
                                                maxWidth={innerWidth}
                                                minWidth={innerWidth}
                                                boxShadow="none"
                                            />
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </>
                }
                <CardContent>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        p={1}
                        m={1}
                        mb={0}
                    >           
                        {
                            !isMobile && <>
                              <Grid container className={classes.chartRejectedOrders}>
                                {
                                    chartsData.map(d => {
                                        if (d.hideEnv) return null;
                                        return (
                                            <Box p={1} m={1}>
                                                <EMEAChartCard
                                                    {...d}
                                                    key={d.subtype}
                                                />
                                            </Box>
                                        )
                                    })
                                }
                                 </Grid>
                            </>
                        } 
                    </Box>
                </CardContent>
            </Card>
        </>
    )
}

export default EMEAHome;
