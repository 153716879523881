import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { CTLink } from 'components/common/CTLink';
import CloseIcon from '@material-ui/icons/Close';
import HamburgerIcon from 'assets/images/hamburger.svg';
import HomeIcon from 'assets/images/home_dark.svg';
import TruckReturnsIcon from 'assets/images/truck_returns.svg';
import OrderEntryIcon from 'assets/images/emea-order-entry.svg';
import OpenOrdersIcon from 'assets/images/package.svg';
import TutorialsIcon from 'assets/images/clipboard.svg';
import OrderCutsAndRejectionsIcon from 'assets/images/cutsAndRejections.svg';
import InvoiceSummaryIcon from 'assets/images/invoices.svg';
import qs from "query-string";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    '&:hover': {
      background: theme.palette.primary.pressed
    }
  },
  menuButtonFocus: {
    background: theme.palette.primary.pressed,
    borderRadius: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1)
  },
  appdrawer: {
    flexShrink: 0
  },
  paper: {
    width: 300,
    backgroundColor: theme.palette.background.drawer,
    borderRight: 'none',
    top: theme.spacing(8),
    boxShadow: `0px ${theme.spacing(1.5)}px ${theme.spacing(2.5)}px 0px rgba(0, 0, 0, 0.12)`,
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(7.25)
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    backgroundColor: theme.palette?.background?.primaryBase,
    color: theme.palette.white,
    minHeight: 50
  },
  drawerSpan: {
    display: 'inline-flex',
    cursor: 'pointer'
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  listItems: {
    borderBottom: `2px solid ${theme.palette?.primary?.pressed}`
  },
  icon: {
    width: theme.spacing(3)
  }
}));

const EmeaHamburger = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openDrawer, setDrawerOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const excludedUrlParam = ["invoiced"];
  const tutorialsLink = process.env.REACT_APP_EMEA_TUTORIALS_URL;
  const goToPage = (page) => {
    const parsed = qs.parse(location.search)
    let regx = new RegExp("[^\/]+$", "g");
    let excludedUrlValue = location?.pathname.split('/')[5];
    let path = excludedUrlParam.includes(excludedUrlValue) ? location?.pathname.replace(`/${excludedUrlValue}`, "") : location?.pathname;
    let pathname = path.replace(regx, page);
    history.push({
      ...location,
      search: qs.stringify({
        ...parsed,
        documentDate: undefined,  
        loadView: true
      }),
      pathname
    })
  }

  return (
    <>
      <IconButton
        data-testid="menu"
        aria-label="open drawer"
        onClick={() => setDrawerOpen(!openDrawer)}
        edge="start"
        className={openDrawer ? classes.menuButtonFocus : classes.menuButton}>
        <img src={HamburgerIcon} alt="menu" />
      </IconButton>

      <Drawer
        data-testid="app-drawer"
        className={classes.appdrawer}
        variant="persistent"
        anchor="left"
        open={openDrawer}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.drawerHeader}>
          <span className={classes.drawerSpan} onClick={() => setDrawerOpen(!openDrawer)}>
            <Typography data-testid="closeMenu" variant="body1" > {t("close_menu")} </Typography>
            <CloseIcon fontSize='small' />
          </span>
        </div>
        <List>
        <ListItem button key={'Home'} className={classes.listItems} onClick={() => goToPage("home")}>
            <CTLink className={classes.link} >
              <ListItemIcon> <img src={HomeIcon} alt="home-icon" className={classes.icon} /> </ListItemIcon>
              <ListItemText primary={t('home')} />
            </CTLink>
          </ListItem>
          <ListItem active button key={'Returns'} className={classes.listItems} onClick={() => goToPage("returns")}>
            <CTLink className={classes.link} >
              <ListItemIcon> <img src={TruckReturnsIcon} alt="truck-icon" className={classes.icon} /> </ListItemIcon>
              <ListItemText primary={t('customer_returns')} />
            </CTLink>
          </ListItem>
          <ListItem active button key={'Order Entry'} className={classes.listItems} onClick={() => goToPage("orderEntry")}>
            <CTLink className={classes.link} >
              <ListItemIcon> <img src={OrderEntryIcon} alt="order-entry-icon" className={classes.icon} /> </ListItemIcon>
              <ListItemText primary={t('order_entry')} />
            </CTLink>
          </ListItem>
          <ListItem active button key={'Open Orders'} className={classes.listItems} onClick={() => goToPage("openOrders")}>
            <CTLink className={classes.link} >
              <ListItemIcon> <img src={OpenOrdersIcon} alt="open-orders-icon" className={classes.icon} /> </ListItemIcon>
              <ListItemText primary={t('open_orders')} />
            </CTLink>
          </ListItem>
          <ListItem active button key={'Order Rejections'} className={classes.listItems} onClick={() => goToPage("orderCutsRejections")}>
            <CTLink className={classes.link} >
              <ListItemIcon> <img src={OrderCutsAndRejectionsIcon} alt="order-cuts-icon" className={classes.icon} /> </ListItemIcon>
              <ListItemText primary={t('order_cuts_and_rejections')} />
            </CTLink>
          </ListItem>
          {["local", "development"].includes(process.env.REACT_APP_ENV) && <ListItem active button key={'Invoice Summary'} className={classes.listItems} onClick={() => goToPage("invoices")}>
            <CTLink className={classes.link} >
              <ListItemIcon> <img src={InvoiceSummaryIcon} alt="order-cuts-icon" className={classes.icon} /> </ListItemIcon>
              <ListItemText primary={t('invoice_summary')} />
            </CTLink>
          </ListItem>}
          <ListItem active button key={'Tutorials & Resources'} className={classes.listItems} >
            <CTLink className={classes.link} target="_blank" href={tutorialsLink} >
              <ListItemIcon> <img src={TutorialsIcon} alt="tutorials-icon" className={classes.icon} /> </ListItemIcon>
              <ListItemText primary={t('tutorials_&_resources')} />
            </CTLink>
          </ListItem>
        </List>

      </Drawer>
    </>
  )
}

export default EmeaHamburger;